import React from 'react'
import { Link } from 'gatsby'

import {
  JobPositionsWrapper,
  JobPositionsContent,
  Heading,
  Title,
  Text,
  StyledLink,
  JobPositionsList,
  NoJobPosition
} from './styles'
import Container from '../UI/Container'
import { LocaleConsumer } from '../Layout'

const JobPositions = ({ data, locale, location }) => {
  const {
    acf: { title, text, link_text, url, open_positions }
  } = data[0].node

  const page = locale === 'en' ? `/${locale}/careers/` : `/karijera/`

  const JobPositionItem = ({ position, i18n }) => {
    const { job_position, work_location, slug } = position

    if (work_location === 'not-avaliable') {
      return (
        <NoJobPosition href="https://rentlio.talentlyft.com/jobs/open-application-wim/new">
          <h6>
            {i18n.jobApplyMessage}
            <a href="https://rentlio.talentlyft.com/jobs/open-application-wim/new"></a>
          </h6>
        </NoJobPosition>
      )
    }
    return (
      <li>
        <Link to={`${page}${slug}`}>
          <h6>{job_position}</h6>
          <span>{work_location}</span>
        </Link>
      </li>
    )
  }
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <JobPositionsWrapper>
          <Container>
            <JobPositionsContent>
              <Heading>
                <Title>{title}</Title>
                <Text>{text}</Text>
              </Heading>
              <StyledLink to={`${page}${location.search}`}>{link_text}</StyledLink>
            </JobPositionsContent>
            <JobPositionsList text={i18n.jobApply}>
              {open_positions.map((position, i) => (
                <JobPositionItem key={i} position={position} i18n={i18n} />
              ))}
            </JobPositionsList>
          </Container>
        </JobPositionsWrapper>
      )}
    </LocaleConsumer>
  )
}

JobPositions.defaultProps = {
  location: {
    search: ''
  }
}

export default JobPositions
