import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import qs from 'qs'
import fetch from 'isomorphic-fetch'
import 'es6-promise/auto'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import {
  ContactFormSection,
  ContactFormWrapper,
  FormButtons,
  SubmitButton,
  AddressBox,
  StyledLink,
  PrivacyBox,
  ErrorMsg,
  ConformationMsg,
  SendingWrapper
} from './styles'

import Container from '../../UI/Container'
import FormInput from '../../Form-input'
import { LocaleConsumer } from '../../Layout'

const ContactForm = ({ data, locale }) => {
  const {
    address: { title, street, town, country, button_text, button_link }
  } = data

  const [state, setState] = useState({ name: '', email: '', message: '' })
  const [msg, setMsg] = useState(false)
  const [errors, setErrors] = useState({ name: '', email: '', message: '' })
  const [sending, setSending] = useState(false)
  const mixpanel = useMixpanel()

  const handleSubmit = (e) => {
    setSending(true)

    fetch(`${process.env.APP_BASE_URL}${locale}/contact-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: qs.stringify(state)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'ERROR') {
          data.errors.map((error) => {
            Object.keys(errors).map((key) => {
              setErrors((errors) => ({ ...errors, [error.filed]: error.message }))
            })
            return error
          })
          setSending(false)
          setTimeout(() => {
            setMsg(false)
            setErrors({ name: '', email: '', message: '' })
          }, 6000)
          return
        }

        handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'contact_form_submitted', 'contact_form', false, true)
        setMsg(true)
        setState({ name: '', email: '', message: '' })
        setSending(false)
        setTimeout(() => {
          setMsg(false)
        }, 6000)
      })
      .catch((error) => console.log('Error: ', error))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  const Sending = () => {
    return (
      <SendingWrapper>
        <span />
        <span />
        <span />
      </SendingWrapper>
    )
  }

  const privacy = locale === 'en' ? `/${locale}/privacy-policy` : '/izjava-o-privatnosti'
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <ContactFormSection>
          <Container>
            <ContactFormWrapper>
              <div className="form">
                <FormInput
                  type="text"
                  name="name"
                  value={state.name}
                  handleChange={handleChange}
                  label={i18n.yourFullName}
                  active={state.name !== ''}
                  autoComplete="off"
                  transparent
                  grey
                  lightGrey
                >
                  {errors.name && <ErrorMsg>{errors.name}</ErrorMsg>}
                </FormInput>

                <FormInput
                  type="email"
                  name="email"
                  value={state.email}
                  handleChange={handleChange}
                  active={state.email !== ''}
                  label={i18n.yourEmail}
                  autoComplete="off"
                  transparent
                  grey
                  lightGrey
                >
                  {errors.email && <ErrorMsg>{errors.email}</ErrorMsg>}
                </FormInput>

                <FormInput
                  type="textarea"
                  name="message"
                  value={state.message}
                  handleChange={handleChange}
                  label={i18n.yourMessage}
                  active={state.message !== ''}
                  transparent
                  grey
                  lightGrey
                >
                  {errors.message && <ErrorMsg>{errors.message}</ErrorMsg>}
                </FormInput>

                <FormButtons>
                  <SubmitButton onClick={handleSubmit}>{sending ? <Sending /> : i18n.contactSubmit}</SubmitButton>
                  {msg && <ConformationMsg>{i18n.messageSent}</ConformationMsg>}
                </FormButtons>
                <PrivacyBox>
                  <span>
                    {i18n.checkPrivacy}
                    <Link to={privacy}> {i18n.privacyPolicy}.</Link>
                  </span>
                </PrivacyBox>
              </div>
            </ContactFormWrapper>
          </Container>
        </ContactFormSection>
      )}
    </LocaleConsumer>
  )
}

export default ContactForm
