import styled from 'styled-components'
import { Link } from 'gatsby'

import RightArrow from '../../images/right-white-arrow.svg'

export const JobPositionsWrapper = styled.section`
  padding: 5rem 0;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};

  @media screen and (max-width: 425px) {
    padding: 3rem 0;
  }
`

export const JobPositionsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
`

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.veryLightGray};
  font-size: 1.14rem;
  line-height: 1.8rem;

  @media only screen and (max-width: 1024px) {
    margin: 1rem 0 2rem 0;
  }
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-weight: bold;
  text-decoration: none;
  font-size: 1.28rem;
  line-height: 1.4rem;
  text-transform: uppercase;

  &::after {
    content: '';
    display: inline-flex;
    width: 14px;
    height: 11px;
    background-image: url(${RightArrow});
    background-repeat: no-repeat;
    margin-left: 1rem;
  }
`
export const JobPositionsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 8rem 0 5rem 0;

  li {
    background: ${({ theme }) => theme.colors.almostAsDarkBlue};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(29, 158, 217, 0.5);
    border-radius: 8px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;

    a {
      display: block;
      padding: 2.5rem 2.5rem;

      &::after {
        ${({ text }) => `content:"${text}"`};
        color: ${({ theme }) => theme.colors.vividBlue};
        font-size: 1.14rem;
        font-weight: 500;
        line-height: 1.71rem;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0%, 50px);
        transition: all 0.2s ease-in-out;
        opacity: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);

      a::after {
        opacity: 1;
        transform: translate(-65%, 50px);
      }
    }

    h6 {
      color: ${({ theme }) => theme.colors.lightestGrey};
      font-family: 'Barlow';
      font-size: 1.42rem;
      font-weight: 500;
    }

    span {
      color: ${({ theme }) => theme.colors.lightGrey};
    }
  }

  @media only screen and (max-width: 545px) {
    li a {
      padding: 1rem;

      h6 {
        font-size: 1.2rem;
      }
    }

    li::after {
      display: none;
    }
  }
`
export const NoJobPosition = styled.a`
  background: ${({ theme }) => theme.colors.almostAsDarkBlue};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(29, 158, 217, 0.5);
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out;
  text-align: left;
  padding: 2.5rem 2.5rem;
  cursor: pointer;

  h6 {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-family: 'Barlow';
    font-size: 1.42rem;
    font-weight: 500;
  }

  a {
    color: ${({ theme }) => theme.colors.vividBlue};
  }

  @media only screen and (max-width: 425px) {
    h6 {
      text-align: center;
    }
  }
`
