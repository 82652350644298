import styled from 'styled-components'

export const ContactIntroWrapper = styled.div`
  padding: 8rem 0 0;
  height: 500px;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
`
export const ContactIntroBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    text-align: center;
    margin-bottom: 2rem;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 6rem;
  font-weight: 900;
  line-height: 6.2rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    margin-top: 4rem;
  }

  @media screen and (max-width: 425px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.42rem;
  line-height: 2.57rem;

  a {
    color: ${({ theme }) => theme.colors.vividBlue};
    position: relative;

    &:hover {
      ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        background-color: ${({ theme }) => theme.colors.vividBlue};
        opacity: 0.3;
        bottom: 2px;
        left: 0;
      }
    }
  }

  @media screen and (max-width: 425px) {
    font-size: 1.1rem;
    line-height: 1.7rem;
    width: 90%;
    margin: 1rem auto 0 auto;
  }
`
export const ImageContainer = styled.figure`
  /* display: flex;
  align-items: center; */
  max-width: 800px;
  height: 350px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
`
