import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import JobPositions from 'components/Job-positions'
import ContactIntro from 'components/Contact-screen/Contact-intro'
import ContactForm from 'components/Contact-screen/Contact-form'

const MainContent = styled.main`
  background-color: #ffffff;
`

const Contact = ({ data, pageContext, location }) => {
  const {
    contact: {
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      contactData
    },
    job_positions: { edges: position }
  } = data

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header mode="dark" locale={pageContext.locale} opacity={0.3} location={location} />
      <ContactIntro data={contactData} />
      <MainContent>
        <ContactForm data={contactData} locale={pageContext.locale} />
        <JobPositions data={position} />
      </MainContent>
      <Footer locale={pageContext.locale} legalinfo location={location} />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query($id: Int, $locale: String!) {
    contact: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      contactData: acf {
        title
        subtitle
        address {
          title
          street
          town
          country
          button_text
          button_link
        }
      }
    }
    job_positions: allWordpressWpJobs(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          acf {
            title
            text
            link_text
            url
            open_positions {
              slug
              job_position
              work_location
            }
          }
        }
      }
    }
  }
`
