import styled from 'styled-components'

export const ContactFormSection = styled.section`
  padding: 5rem 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100px;
    bottom: 1px;
    transform: translateY(100%);

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 0, 100% 100%, 100% 100%, 100% 0);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 0, 100% 50%, 100% 100%, 100% 0);
    }
  }
`

export const ContactFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;

  .form {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .form {
      width: 100%;
      align-items: center;
    }
  }

  @media screen and (max-width: 425px) {
    margin-bottom: 1rem;
  }
`

export const FormButtons = styled.div`
  display: flex;
  margin-top: 2rem;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    margin-bottom: 5rem;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
  }
`
export const SubmitButton = styled.button`
  display: block;
  background: ${({ theme }) => theme.colors.vividBlue};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.pureWhite};
  font-size: 1.28rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 19px 3rem;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  border: none;
  margin-right: 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.5);
    background-color: hsla(199, 76%, 43%, 1);
    transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.3);
  }

  @media only screen and (max-width: 768px) {
    padding: 19px 2rem;
  }

  @media screen and (max-width: 425px) {
    margin: 0;
  }
`
export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.vividBlue};
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;
  position: relative;
  align-self: center;
  cursor: pointer;

  &:hover {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      opacity: 0.3;
      bottom: 3px;
      left: 0;
    }
  }
`
export const PrivacyBox = styled.div`
  display: block;
  margin-top: 2rem;

  span {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1;
  }

  a {
    color: ${({ theme }) => theme.colors.vividBlue};

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 425px) {
    margin: 0 0 4rem 0;
  }
`

export const ConformationMsg = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  animation: fadeIn 0.2s ease-in-out;
  color: #96b43e;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const ErrorMsg = styled.div`
  position: absolute;
  color: #f35b5d;
  height: 22px;
  animation: fadeIn 0.2s ease-in-out;
  transform: translateY(-20px);

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
export const SendingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0.47rem auto;

  span {
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    background-color: ${({ theme }) => theme.colors.pureWhite};
    border-radius: 50%;
    animation: bounce 0.4s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    20% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`
