import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { ContactIntroWrapper, ContactIntroBox, Description, Title, Text, ImageContainer } from './styles'

import Container from '../../UI/Container'

const ContactIntro = ({ data }) => {
  const { title, subtitle } = data
  const {
    file: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/contact-hero-image.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContactIntroWrapper>
      <Container>
        <ContactIntroBox>
          <Description>
            <Title>{title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: subtitle }} />
          </Description>
          <ImageContainer>
            <Img fluid={fluid} placeholderStyle={{ visibility: 'hidden' }} />
          </ImageContainer>
        </ContactIntroBox>
      </Container>
    </ContactIntroWrapper>
  )
}

export default ContactIntro
